<template>
  <div class="navbar" :class="{ collapsed }">
    <b-navbar-brand to="/" :title="version">
      <img class="expanded" src="/images/AugmentLogo_Horizontal_black_backgr.svg" alt="Augment" />
      <img class="collapsed" src="/images/augment-logo-square.svg" alt="Augment" />
      <span v-if="backendName" class="navbar-brand__tag" @click.prevent="$emit('backend')">{{ backendName }}</span>
      <span v-if="showVersion" class="version">{{ version }}</span>
    </b-navbar-brand>

    <div class="nav--toggle">
      <MainMenu>
        <MenuItem @click="$emit('update:collapsed', !collapsed)">
          <BIconArrowBarLeft class="expanded" />
          <BIconArrowBarRight class="collapsed" />
        </MenuItem>
      </MainMenu>
    </div>

    <div class="nav--primary expanded">
      <MainMenu>
        <MenuItem v-if="canAccessPublicMatches" :active="$route.path === '/matches'" to="/matches">
          Pro Matches
        </MenuItem>
        <MenuItem
          v-if="canAccessGridEmeaMatches"
          :active="$route.path === '/grid/matches/upcoming'"
          to="/grid/matches/upcoming"
        >
          Upcoming Matches
        </MenuItem>
        <MenuItem :active="$route.path === '/grid/matches'" to="/grid/matches"> Grid Matches </MenuItem>
        <MenuItem v-if="false" :active="$route.path === '/collegiate/matches'" to="/collegiate/matches">
          Collegiate Matches
        </MenuItem>
        <MenuItem disabled> Teams </MenuItem>
        <MenuItem v-if="canAccessScrimsV1" :active="$route.path === '/scrims'" to="/scrims"> Scrims </MenuItem>
        <MenuItem v-if="canAccessGridScrims" :active="$route.path === '/grid/scrims'" to="/grid/scrims">
          Grid Scrims
        </MenuItem>
        <MenuItem v-if="canAccessScrimsV2" :active="$route.path === '/scrims2'" to="/scrims2">
          Overwolf Scrims
        </MenuItem>
        <MenuItem v-if="canAccessScrimsV2" :active="$route.path === '/scrim-stats'" to="/scrim-stats">
          Scrim Stats (OW)
        </MenuItem>
        <MenuItem disabled> Maps </MenuItem>
      </MainMenu>
    </div>

    <div class="nav--secondary expanded">
      <MainMenu>
        <MenuItem :active="$route.name === 'Feedback'" to="/feedback"> Submit feedback </MenuItem>
        <MenuItem v-if="!is_authenticated" :active="$route.name === 'Login'" :href="ssoUrl"> Login </MenuItem>
        <MenuItem v-if="is_authenticated" :active="profileMenuOpen" @click="profileMenuOpen = !profileMenuOpen">
          {{ username }}
        </MenuItem>
        <div v-if="profileMenuOpen && is_authenticated" class="ml-2">
          <MenuItem :active="$route.name === 'Profile'" href="https://sso.augment.gg/"> Account </MenuItem>
          <MenuItem :active="$route.name === 'Billing'" href="https://shop.augment.gg/"> Billing </MenuItem>
          <MenuItem @click="$emit('logout')"> Log out </MenuItem>
        </div>
      </MainMenu>
    </div>
  </div>
</template>

<script>
import { BIconArrowBarRight, BIconArrowBarLeft } from 'bootstrap-vue'
import px from 'vue-types'
import { mapGetters } from 'vuex'

import { ssoUrl } from '@/utils/sso'

import MainMenu from '../Map/components/MainMenu.vue'
import MenuItem from '../Map/components/MenuItem.vue'

export default {
  name: 'AppNavigation',
  components: {
    BIconArrowBarRight,
    BIconArrowBarLeft,
    MenuItem,
    MainMenu,
  },
  props: {
    backendName: px.string,
    collapsed: px.bool.def(false),
  },
  data: () => ({
    profileMenuOpen: false,
  }),
  computed: {
    ...mapGetters({
      username: 'auth/username',
      is_authenticated: 'auth/is_authenticated',
      canAccessGridEmeaMatches: 'auth/can_access_grid_emea_matches',
      canAccessPublicMatches: 'auth/can_access_public_matches',
      // canAccessCollegiateMatches: 'auth/can_access_collegiate_matches',
      canAccessScrimsV1: 'auth/can_access_scrims_v1',
      canAccessGridScrims: 'auth/can_access_grid_scrims',
      canAccessScrimsV2: 'auth/can_access_scrims_v2',
    }),
    debugPanelEnabled() {
      return process.env.VUE_APP_DEBUG_PANEL === 'true'
    },
    showVersion() {
      return process.env.VUE_APP_SHOW_VERSION === 'yes'
    },
    ssoUrl() {
      return ssoUrl()
    },
    version() {
      return (process.env.VUE_APP_VERSION || '').split('@').pop()
    },
  },
}
</script>

<style lang="scss" scoped>
.navbar {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: stretch;
  flex-shrink: 0;
  min-height: 100%;

  .navbar-brand {
    margin: 0 0 1em;
  }

  .navbar-text,
  .nav-item {
    text-align: center;
    padding: 0.75em 0.5em;
  }
}

.vue-portal-target {
  display: flex;
  align-items: stretch;
  flex-flow: column nowrap;
}

.nav {
  &--secondary {
    margin-top: auto;
  }
}

.menu-item {
  .menu-btn {
    pointer-events: all;
  }
}

.navbar {
  &:not(.collapsed) {
    .collapsed {
      display: none;
    }
  }

  &.collapsed {
    .expanded {
      display: none;
    }

    & > * {
      padding-left: 0.125rem;
      padding-right: 0.125rem;
    }

    .navbar-brand {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;

      &__tag {
        position: static;
      }
    }

    .navbar-text,
    .nav-item {
      padding: 0.75em 0.125em;
    }

    .menu-item::v-deep {
      .menu-btn {
        padding: 0.5em 0.125em;
      }
    }
  }

  .version {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    font-size: 0.5em;
    line-height: 1;
    color: lighten($body-bg, 10%);
    text-align: center;
  }
}
</style>
