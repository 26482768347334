var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar", class: { collapsed: _vm.collapsed } },
    [
      _c("b-navbar-brand", { attrs: { to: "/", title: _vm.version } }, [
        _c("img", {
          staticClass: "expanded",
          attrs: {
            src: "/images/AugmentLogo_Horizontal_black_backgr.svg",
            alt: "Augment"
          }
        }),
        _c("img", {
          staticClass: "collapsed",
          attrs: { src: "/images/augment-logo-square.svg", alt: "Augment" }
        }),
        _vm.backendName
          ? _c(
              "span",
              {
                staticClass: "navbar-brand__tag",
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.$emit("backend")
                  }
                }
              },
              [_vm._v(_vm._s(_vm.backendName))]
            )
          : _vm._e(),
        _vm.showVersion
          ? _c("span", { staticClass: "version" }, [
              _vm._v(_vm._s(_vm.version))
            ])
          : _vm._e()
      ]),
      _c(
        "div",
        { staticClass: "nav--toggle" },
        [
          _c(
            "MainMenu",
            [
              _c(
                "MenuItem",
                {
                  on: {
                    click: function($event) {
                      return _vm.$emit("update:collapsed", !_vm.collapsed)
                    }
                  }
                },
                [
                  _c("BIconArrowBarLeft", { staticClass: "expanded" }),
                  _c("BIconArrowBarRight", { staticClass: "collapsed" })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "nav--primary expanded" },
        [
          _c(
            "MainMenu",
            [
              _vm.canAccessPublicMatches
                ? _c(
                    "MenuItem",
                    {
                      attrs: {
                        active: _vm.$route.path === "/matches",
                        to: "/matches"
                      }
                    },
                    [_vm._v(" Pro Matches ")]
                  )
                : _vm._e(),
              _vm.canAccessGridEmeaMatches
                ? _c(
                    "MenuItem",
                    {
                      attrs: {
                        active: _vm.$route.path === "/grid/matches/upcoming",
                        to: "/grid/matches/upcoming"
                      }
                    },
                    [_vm._v(" Upcoming Matches ")]
                  )
                : _vm._e(),
              _c(
                "MenuItem",
                {
                  attrs: {
                    active: _vm.$route.path === "/grid/matches",
                    to: "/grid/matches"
                  }
                },
                [_vm._v(" Grid Matches ")]
              ),
              false
                ? _c(
                    "MenuItem",
                    {
                      attrs: {
                        active: _vm.$route.path === "/collegiate/matches",
                        to: "/collegiate/matches"
                      }
                    },
                    [_vm._v(" Collegiate Matches ")]
                  )
                : _vm._e(),
              _c("MenuItem", { attrs: { disabled: "" } }, [_vm._v(" Teams ")]),
              _vm.canAccessScrimsV1
                ? _c(
                    "MenuItem",
                    {
                      attrs: {
                        active: _vm.$route.path === "/scrims",
                        to: "/scrims"
                      }
                    },
                    [_vm._v(" Scrims ")]
                  )
                : _vm._e(),
              _vm.canAccessGridScrims
                ? _c(
                    "MenuItem",
                    {
                      attrs: {
                        active: _vm.$route.path === "/grid/scrims",
                        to: "/grid/scrims"
                      }
                    },
                    [_vm._v(" Grid Scrims ")]
                  )
                : _vm._e(),
              _vm.canAccessScrimsV2
                ? _c(
                    "MenuItem",
                    {
                      attrs: {
                        active: _vm.$route.path === "/scrims2",
                        to: "/scrims2"
                      }
                    },
                    [_vm._v(" Overwolf Scrims ")]
                  )
                : _vm._e(),
              _vm.canAccessScrimsV2
                ? _c(
                    "MenuItem",
                    {
                      attrs: {
                        active: _vm.$route.path === "/scrim-stats",
                        to: "/scrim-stats"
                      }
                    },
                    [_vm._v(" Scrim Stats (OW) ")]
                  )
                : _vm._e(),
              _c("MenuItem", { attrs: { disabled: "" } }, [_vm._v(" Maps ")])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "nav--secondary expanded" },
        [
          _c(
            "MainMenu",
            [
              _c(
                "MenuItem",
                {
                  attrs: {
                    active: _vm.$route.name === "Feedback",
                    to: "/feedback"
                  }
                },
                [_vm._v(" Submit feedback ")]
              ),
              !_vm.is_authenticated
                ? _c(
                    "MenuItem",
                    {
                      attrs: {
                        active: _vm.$route.name === "Login",
                        href: _vm.ssoUrl
                      }
                    },
                    [_vm._v(" Login ")]
                  )
                : _vm._e(),
              _vm.is_authenticated
                ? _c(
                    "MenuItem",
                    {
                      attrs: { active: _vm.profileMenuOpen },
                      on: {
                        click: function($event) {
                          _vm.profileMenuOpen = !_vm.profileMenuOpen
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.username) + " ")]
                  )
                : _vm._e(),
              _vm.profileMenuOpen && _vm.is_authenticated
                ? _c(
                    "div",
                    { staticClass: "ml-2" },
                    [
                      _c(
                        "MenuItem",
                        {
                          attrs: {
                            active: _vm.$route.name === "Profile",
                            href: "https://sso.augment.gg/"
                          }
                        },
                        [_vm._v(" Account ")]
                      ),
                      _c(
                        "MenuItem",
                        {
                          attrs: {
                            active: _vm.$route.name === "Billing",
                            href: "https://shop.augment.gg/"
                          }
                        },
                        [_vm._v(" Billing ")]
                      ),
                      _c(
                        "MenuItem",
                        {
                          on: {
                            click: function($event) {
                              return _vm.$emit("logout")
                            }
                          }
                        },
                        [_vm._v(" Log out ")]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }